/*@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');
*{
    /*font-family: 'Ubuntu', sans-serif;*/
    /*font-family: 'Caveat', sans-serif;*/

    /*font-family: 'M PLUS Rounded 1c', sans-serif;*/

    font-family: 'Comfortaa', sans-serif;
}

.footer-section, .social-media-section {
    padding-top: 20px;
    background: #f0f0f0;
}

.col:first-of-type {
    /*background: #ffffff*/
}

.col:last-of-type {
    /*border: orange solid;*/
    /*height: 40vh;*/
}

.bg-bottom {
    background: #e3e3e3
}

a{
    text-decoration: none!important;
    color: #1a1e21!important;
}

a:hover{
    color: black!important;
}

.bg-black{
    font-family: monospace;
    font-size: 16px;
    line-height: 1.45;
    height: 100vh;
    padding: 16px;
    background-image: linear-gradient( to top, #000000, #515151);
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-black2{
    font-family: monospace;
    font-size: 16px;
    line-height: 1.45;
    height: 100vh;
    padding: 16px;
    background-image: linear-gradient( to top, #515151, #000000);
    background-size: cover;
    background-repeat: no-repeat;
}

.container-body {
    height: 2500px;
}
.box {
    width: 100px;
    height: 100px;
    background-color: hotpink;
    margin-top: 50rem;
    color: white;
    font-size: 20px;
}
.box2 {
    width: 100px;
    height: 100px;
    background-color: hotpink;
    margin-top: 0rem;
    color: white;
    font-size: 20px;
}

#gift{
    font-family: monospace;
    font-size: 16px;
    line-height: 1.45;
    padding: 16px;
    background-image: linear-gradient( to top, #000000, #515151);
    background-size: cover;
    background-repeat: no-repeat;
}

#bg{
    background-color: black;
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}

#slogan{
    height: 300px;
    /*background: rgb(65, 65, 65);*/
    background: linear-gradient(180deg, rgb(14, 14, 14) 0%, rgba(255,255,255,1) 100%);
    /*background-image: linear-gradient( to right, #ffffff, #000000);*/
}

#slogan2{
    /*height: 500px;*/
    /*background: rgb(65, 65, 65);*/
    /*background: linear-gradient(180deg, rgb(14, 14, 14) 0%, rgba(255,255,255,1) 100%);*/
    background-image: linear-gradient(-250deg, #ffffff, #000000);
}